import WsInternal from '@/services/api/util/websocket-internal';
import { convertToDefaultDateHourFormat } from '@/util';

export default class CandidateDisabled extends WsInternal {
  constructor(disabledModel, token) {
    super(process.env.VUE_APP_CRM_WS + `candidates_broadcast?auth_token=${token}`);
    this.disabledModel = disabledModel;
  }

  startListen(getListCallback, callback, customListen) {
    this.ws.onmessage = event => {
      const wsMessageData = this.getMsgData(event);
      if (!wsMessageData) return;
      if(customListen) return customListen(wsMessageData, getListCallback())
      this.setCandidateData(wsMessageData, getListCallback(), callback);
    };
  }

  setCandidateData(candidate, list, callback) {
    let item = list.find(item => item.ID === candidate.id)
    if (!item) return
    for (let field in candidate) {
      if (field === 'exam') {
        this.setReservedExam(item, candidate.exam)
        continue
      }
      if (!item._apiAttributes[field]) continue
      item[item._apiAttributes[field]] = candidate[field]
    }
    if (this.disabledModel) this.disabledModel.checkCBRStatus(item.status)
    this._setAnimation(item)
    if (callback) callback(candidate, item)
  }

  setReservedExam(item, reservedExam) {
    if (!reservedExam) {
      for (let field in item.exam) {
        item.exam[field] = null
      }
      return;
    }
    if (reservedExam.location) item.exam.location = reservedExam.location;
    if (reservedExam.datetime) item.exam.examDate = convertToDefaultDateHourFormat(reservedExam.datetime);
    if (reservedExam.reservation_code) item.exam.code = reservedExam.reservation_code;
    if (reservedExam.journey) item.exam.journey = reservedExam.journey;
  }

  _setAnimation(item) {
    item.animation = true;
    setTimeout(() => {
      item.animation = false;
    }, 1000);
  }

  close() {
    this.ws.close();
  }
}
